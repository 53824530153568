import React, { FC } from "react"

import { Button } from "@launchacademy/voyager"

import "./css/blogPost.css"
import { BlogTagsProps } from "./BlogPost.d"

export const BlogTags: FC<BlogTagsProps> = ({ tags }) => {
  const tagList = tags.map((tag) => {
    return (
      <li key={tag.url}>
        <Button placement="photo" size="sm" text={tag.name} to={tag.url} />
      </li>
    )
  })

  return <ul className="tag-container__tags">{tagList}</ul>
}
