import * as React from "react"

import { CardList } from "@launchacademy/voyager"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import _ from "lodash"

import { BlogTags } from "../../components/blogPosts/BlogTags"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { columns } from "../../pageData/blogPostData.js"
import buildImage from "../../services/buildImage"

import "./css/index.css"

const BlogIndex = ({ data, location }) => {
  const articles = data.latestArticles.nodes.map((article) => {
    return {
      Image: buildImage(article?.image?.localFile),
      header: article.articleTitle,
      articleUrl: `/blog/${article.slug}`,
      cardType: "article",
      body: article.description || article.contentPreview,
      categories: [
        {
          name: _.startCase(article.category.name.replace("-", " ")),
          url: `/blog/category/${article.category.slug}`,
        },
      ],
      timeToRead: Math.round(article.timeToRead.minutes),
    }
  })

  const tags = data.categories.nodes.map((category) => {
    return {
      name: _.startCase(category.name.replace("-", " ")),
      url: `/blog/category/${category.slug}`,
    }
  })
  return (
    <Layout>
      <Seo
        title="Blog Articles from the Launch Academy Team"
        description="Read the latest from the team at Launch Academy. We'll discuss software development, learning to code, and launching your software engineering career"
        pathname={location.pathname}
      />
      <section className="hero">
        <div className="hero__image-column_square">
          <div className="hero__image-container_square">
            <GatsbyImage
              image={getImage(data.codeNames)}
              alt="Launchers Playing Codenames"
              className="hero__image_square hero__image_sm"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h1 className="hero__image_title">Blog</h1>
          <p>
            Read the latest from the team at Launch Academy. We'll discuss software development,
            learning to code, and launching your software engineering career.
          </p>
        </div>
      </section>
      <section className="blog-index__tags">
        <BlogTags tags={tags} />
      </section>
      <section className="card-list-container">
        <CardList content={articles} numberOfColumns={columns} />
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query LatestArticles {
    codeNames: file(relativePath: { regex: "/codenames-nick-and-brianna/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    categories: allStrapiCategory(sort: { fields: name, order: ASC }) {
      nodes {
        name
        slug
      }
    }
    latestArticles: allStrapiArticle(limit: 15, sort: { order: DESC, fields: publishedAt }) {
      nodes {
        articleTitle: title
        slug
        publishedAt
        description
        content {
          data {
            content
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                width: 400
                height: 600
                webpOptions: {}
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        category {
          name
          slug
        }
        timeToRead {
          minutes
        }
        contentPreview
      }
      totalCount
    }
  }
`
